import axios from "../utils/axios";

function request(url, method = "get", data = {}, headers = {}) {
  return axios({
    url,
    method,
    data,
    headers,
  });
}
function get(url, data = {}, headers = {}) {
  return request(url, "get", data, headers);
}

function post(url, data = {}, headers = {}) {
  return request(url, "post", data, headers);
}

const api = {
  imageUrl: process.env.VUE_APP_BASH_IMAGE_URL,
  videoUrl: process.env.VUE_APP_BASH_VIDEO_URL,
  formatImageUrl(url) {
    const bashImageUrl = this.imageUrl;
    if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
      return url;
    }
    if (url.indexOf("/") === 0) {
      return bashImageUrl + url.slice(1);
    }
    return bashImageUrl + url;
  },
  formatVideoUrl(url) {
    if (!url) return null;
    const bashVideoUrl = this.videoUrl;
    if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
      return url;
    }
    if (url.indexOf("/") === 0) {
      return bashVideoUrl + url.slice(1);
    }
    return bashVideoUrl + url;
  },
  customer: {
    loadCustomerInfo: (data) => {
      return post("api/SU_Customer/LoadCustomerInfo", data);
    },
  },
  //首页数据
  // home:{
  //   getHomeData:data=>{
  //       return post("api/B_Index/LoadNewIndex", data);
  //   },
  //   // 产品分类
  //   loadClassProductByPage: data => {
  //     return post("api/B_Index/LoadClassProductByPage", data);
  //   },
  //   // 活动
  //   loadBannerProductByPage: data => {
  //     return post("/api/B_Index/B_LoadBannerProductByPage", data);
  //   }
  // },
  //店铺
  countData: {
    // 获取店铺首页统计信息
    countData_BIndex: (data) => {
      return post("api/B_CountData/CountData_BIndex", data);
    },
    // 获取经营分析统计信息
    countData_BManage: (data) => {
      return post("api/B_CountData/CountData_BManage", data);
    },
  },
  follow: {
    // 关注供应商数据
    loadFollowCustomerByPage: (data) => {
      return post("api/B_Follow/LoadFollowCustomerByPage", data);
    },
    // 关注供应商产品
    loadFollowProductByPage: (data) => {
      return post("api/B_Follow/LoadFollowProductByPage", data);
    },
    // 分享产品
    loadShareProductByPage: (data) => {
      return post("api/B_Follow/LoadShareProductByPage", data);
    },
    //根据传入供应商编号加载供应商信息以及分页产品（已实现）
    loadCustomerInfoAndProductByPage: (data) => {
      return post("api/B_Follow/LoadCustomerInfoAndProductByPage", data);
    },
    //关注/取消关注供应商
    editFollowCustomer: (data) => {
      return post("api/B_Follow/EditFollowCustomer", data);
    },
  },
  index: {
    // 首页数据
    loadNewIndex: (data) => {
      return post("api/B_Index/LoadNewIndex", data);
    },
    // 产品分类
    loadClassProductByPage: (data) => {
      return post("api/B_Index/LoadClassProductByPage", data);
    },
    // 活动
    loadBannerProductByPage: (data) => {
      return post("/api/B_Index/B_LoadBannerProductByPage", data);
    },
    // 获取全部分类api/C_Product/LoadProductClass
    loadProductClass: (data) => {
      return post("api/C_Product/LoadProductClass", data);
    },
  },
  login: {
    // 登录操作
    userLogin: (data) => {
      return post("api/B_Login/B_UserLogin", data);
    },
    // 注册账户 注册第一步
    registerUser: (data) => {
      return post("api/B_Login/B_RegisterUser", data);
    },
    // 小铺认证
    registerShopCheckInfo: (data) => {
      return post("api/B_Login/RegisterShopCheckInfo", data);
    },
    // 修改密码
    changeUserPassword: (data) => {
      return post("api/B_Login/B_ChangeUserPassword", data);
    },
    // 验证账号是否注册
    checkRegisterUser: (data) => {
      return post("api/B_Login/CheckRegisterUser", data);
    },
    checkInUser: (data) => {
      return post("api/B_Login/CheckInUser", data);
    },
    // 退出登录
    loginOut: (data) => {
      return get("api/B_Login/LoginOut", data);
    },
    // 获取邀请码二维码
    getQrCodeByBase64: (data) => {
      return post("api/B_Login/B_GetQrCodeByBase64", data);
    },
    // 注销账号
    accountCancellation: data => {
      return post("api/B_Login/B_AccountCancellation", data);
    }
  },
  news: {
    // 分类预览消息
    loadNewsInType: (data) => {
      return post("api/B_News/LoadNewsInType", data);
    },
    // 消息累分类消息
    loadNewsByPage: (data) => {
      return post("api/B_News/LoadNewsByPage", data);
    },
  },
  product: {
    addProductByProductNumber: (data) => {
      return post("api/B_Product/AddProductByProductNumber", data);
    },
    deleteProductByProductNumber: (data) => {
      return post("api/B_Product/DeleteShelvesProducts", data);
    },
    // 分页加载店铺产品列表
    loadShopProductsByPage: (data) => {
      return post("api/B_Product/LoadShopProductsByPage", data);
    },
    // 修改店铺产品上下架状态
    editB_ProductStatus: (data) => {
      return post("api/B_Product/EditB_ProductStatus", data);
    },
    //修改商品是否置顶
    editB_ProductTop: (data) => {
      return post("api/B_Product/EditB_ProductTop", data);
    },
    //根据产品编号 加载产品详情（供应商）
    loadProductInfoByCustomer: (data) => {
      return post("api/B_Product/LoadProductInfoByCustomer", data);
    },
    //根据产品编号 加载产品详情（店铺）
    loadB_ProductInfoByShop: (data) => {
      return post("api/B_Product/LoadB_ProductInfoByShop", data);
    },
    //POST翻译产品信息（已实现）
    editTranslateB_ProductInfo: (data) => {
      return post("api/B_Product/EditTranslateB_ProductInfo", data);
    },
    //修改对应规格参数的价格（已实现）
    editProductModelPrice: (data) => {
      return post("api/B_Product/EditProductModelPrice", data);
    },
    //获取认证分类列表
    getAttestationTypeList: (data) => {
      return post("api/SU_Product/GetAttestationTypeList", data);
    },
  },
  search: {
    //加载搜索推荐关键词
    loadSearchKeyWords: (data) => {
      return post("api/B_Search/LoadSearchKeyWords", data);
    },
    //根据传入搜索关键字加载分页产品
    loadSearchProductByPage: (data) => {
      return post("api/B_Search/LoadSearchProductByPage", data);
    },
  },
  system: {
    // 发送邮箱验证码
    sendEmailCode: (data) => {
      return post("api/B_System/SendEmailCode", data);
    },
    // 验证邮箱验证码
    checkAuthCode: (data) => {
      return post("api/B_System/CheckEmailCode", data);
    },
    // 根据传入的OSVersions 获取对应的app版本信息
    b_LoadAppVersion: (data) => {
      return post("api/B_System/B_LoadAppVersion", data);
    },
    // 上传图片
    uploadImgFile: (data) => {
      return post("api/B_System/UploadImgFile", data);
    },
    //获取平台消息列表
    messageList: (data) => {
      return post("api/C_System/GetPlatformMessageContentList", data);
    },
    //获取平台消息详情
    messageInfo: (data) => {
      return post("api/C_System/GetPlatformMessageContentInfo", data);
    },
    //获取服务类型列表
    getSeriveType: (data) => {
      return post("api/C_System/GetServiceTypeList", data);
    },
    //添加问题反馈
    addProblemFeedback: (data) => {
      return post("api/C_System/AddProblemFeedback", data);
    },
    //问题反馈列表
    supportByPage: (data) => {
      return post("api/C_System/LoadProblemFeedbackByPage", data);
    },
    //问题反馈详情
    getSupportInfo: (data) => {
      return post("api/C_System/LoadProblemFeedbackInfo", data);
    },
    //问题反馈回复
    addSupportReply: (data) => {
      return post("api/C_System/AddProblemReply", data);
    },
  },
  user: {
    // 获取个人信息
    loadUserInfo: (headers) => {
      return post("api/B_User/LoadUserInfo", {}, headers);
    },
    //根据传入分类编号 分页加载产品信息(不传筛选值 默认值为数码分类：50008090)
    loadClassProductByPage: (data) => {
      return post("api/B_User/LoadClassProductByPage", data);
    },
    //财务报表/api/B_User/LoadShopWallet
    loadShopWallet: (data) => {
      return post("api/B_User/LoadShopWallet", data);
    },
    //加载更多财产/api/B_User/LoadShopWalletInfoByPage
    loadShopWalletInfoByPage: (data) => {
      return post("api/B_User/LoadShopWalletInfoByPage", data);
    },
    // 修改用户信息
    EditUserInfo: (data) => {
      return post("api/B_User/EditUserInfo", data);
    },
  },
  shop: {
    //获取店铺信息
    loadShopInfo: (headers) => {
      return post("api/B_Shop/LoadShopInfo", {}, headers);
    },
    //加载店铺（包含热销产品，全部产品
    loadShop: (data) => {
      return post("api/B_Shop/LoadShop", data);
    },
    //获取认证信息
    loadShopCheckInfo: (data) => {
      return post("api/B_Shop/LoadShopCheckInfo", data);
    },
    //店铺申请提现
    addShopWithdraw: (data) => {
      return post("api/B_Shop/addShopWithdraw", data);
    },
    //修改店铺信息
    EditShopInfo: (data) => {
      return post("api/B_Shop/EditShopInfo", data);
    },
    //获取店铺粉丝列表
    getShopFansListByPage: (data) => {
      return post("api/B_Shop/GetShopFansListByPage", data);
    },
    //分享店铺优惠券
    shareShopCoupon: (data) => {
      return post("api/B_Shop/ShareShopCoupon", data);
    },

    //获取可领取优惠券列表
    getCouponListByPage: (data) => {
      return post("api/B_Shop/GetCouponListByPage", data);
    },
    //获取店铺待分享优惠券列表 （ 待分享使用 ）
    getShopShareCouponList: (data) => {
      return post("api/B_Shop/GetShopShareCouponList", data);
    },
    //获取店铺优惠券列表（ 已分享 / 已失效 使用 ）
    getShopCouponListByPage: (data) => {
      return post("api/B_Shop/GetShopCouponListByPage", data);
    },

    //店铺领取优惠券
    addShopCoupon: (data) => {
      return post("api/B_Shop/AddShopCoupon", data);
    },
  },
  order: {
    //加载订单分页列表
    loadOrderByPage: (data) => {
      return post("api/B_Order/LoadOrderByPage", data);
    },
    // 根据订单编号获取订单详情
    loadOrderDetails: (data) => {
      return post("api/B_Order/LoadOrderDetails", data);
    },
    // 获取订单物流
    loadOrderLogistics: (data) => {
      return post("api/B_Order/LoadOrderLogistics", data);
    },
  },
  cshop: {
    //获取店铺信息
    loadShopInfo: (headers) => {
      return post("api/C_Shop/LoadShopInfo", {}, headers);
    },
    //加载店铺（包含热销产品，全部产品
    loadShop: (data) => {
      return post("api/C_Shop/LoadShop", data);
    },
  },
};

export default api;
